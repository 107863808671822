import React, { useEffect, useState } from "react";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import LockIcon from "@mui/icons-material/Lock";
import SendIcon from "@mui/icons-material/Send";
import VerifiedIcon from "@mui/icons-material/Verified";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import NewArtistContainer from "./NewArtistContainer";

import FooterLinks from "../../fragments/FooterLinks";
import AppLogo from "../../assets/app-logo.svg";
import ReleaseDate from "../../fragments/ReleaseDate";

import PageAppBar from "./PageAppBar";

import { API_BASE } from "../../App";
import { setArtistKey, setCurrentUser, setToken } from "../../utils";

export default function ArtistRegisterContainer() {
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+1"); // default to US [+1
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  const [sent, setSent] = useState(false);
  const [verified, setVerified] = useState(false);

  const [accessKey, setAccessKey] = useState("");
  const [access, setAccess] = useState(false);

  const checkAccess = async (key) => {
    const endpoint = API_BASE + "/artist/access";
    const response = await axios.get(endpoint, {
      params: { key: key },
    });
    if (response.data.access) {
      setAccess(true);
    } else {
      alert("Invalid access key. Please contact Julia or Julian.");
      setAccessKey("");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const key = params.get("key");
    if (key) {
      setAccessKey(key);
      checkAccess(key);
    }
  }, []);

  const handleCheckAccess = () => {
    checkAccess(accessKey);
  };

  const formattedPhone = () => {
    return countryCode + phone;
  };

  const sendVerification = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/send_verification";
      const response = await axios.get(endpoint, {
        params: { phone: formattedPhone() },
      });
      setSent(true);
      setLoading(false);
    } catch (error) {
      alert("Something went wrong. Please contact support@notebook.fm");
      window.location.reload();
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/verify";
      const response = await axios.get(endpoint, {
        params: { phone: formattedPhone(), code: code },
      });

      // if response data user
      if (response.data.user) {
        setCurrentUser(response.data.user);
        setToken(response.data.token);
        setArtistKey(response.data.user.key);
        await new Promise((resolve) => setTimeout(resolve, 500)); // do I still need this?
        window.location.href = "/";
      } else if (response.data.verified) {
        setLoading(false);
        setVerified(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error verifying code:", error);
      alert(
        "Something went wrong. Please try again or contact support@notebook.fm"
      );
      window.location.reload();
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // height: "100vh",
      }}
    >
      <PageAppBar />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        sx={{ my: 4 }}
      >
        <img
          src={AppLogo}
          style={{ maxWidth: "300px", marginBottom: "0", padding: "0" }}
        />
        <Typography variant="body1">
          Connecting artists with their audience.
        </Typography>
      </Box>

      {loading && <CircularProgress sx={{ mt: 5 }} />}

      {!access && !loading && (
        <Box sx={{ width: "300px", my: 2 }}>
          <TextField
            label="Access Code Required"
            helperText="Need a code? Email artists@notebook.fm"
            variant="outlined"
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
            style={{ width: "300px" }}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleCheckAccess}
            disabled={accessKey.length < 6}
            startIcon={<VerifiedIcon />}
            style={{ margin: "20px 0" }}
          >
            Get Started
          </Button>
        </Box>
      )}

      {access && !sent && !loading && (
        <Box sx={{ my: 2 }}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              variant="outlined"
              style={{ width: "110px", marginRight: "10px" }}
            >
              <MenuItem value="+1">+1</MenuItem>
              <MenuItem value="+44">+44</MenuItem>
            </TextField>
            <TextField
              label="Phone Number"
              variant="outlined"
              type="telephone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
              fullWidth
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              // submit on enter
              onKeyDown={(e) => {
                if (e.key === "Enter" && phone.length >= 10) {
                  sendVerification();
                }
              }}
            />
          </Box>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={sendVerification}
            disabled={phone.length < 10}
            startIcon={<SendIcon />}
            style={{ margin: "20px 0" }}
          >
            Request code
          </Button>
        </Box>
      )}

      {sent && !verified && !loading && (
        <Box sx={{ width: "300px", my: 2 }}>
          <TextField
            label="Code"
            variant="outlined"
            value={code}
            helperText="Enter the code you received via SMS"
            onChange={(e) => setCode(e.target.value.replace(/[^0-9]/g, ""))}
            fullWidth
            autoComplete="off"
            style={{ margin: "20px 0" }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleVerify();
              }
            }}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleVerify}
            disabled={code.length != 6}
            startIcon={<LockIcon />}
          >
            Confirm
          </Button>
        </Box>
      )}

      {verified && !loading && (
        <NewArtistContainer
          countryCode={countryCode}
          phone={phone}
          loading={loading}
          setLoading={setLoading}
          accessKey={accessKey}
        />
      )}
    </Box>
  );
}
