// FeaturedContainer.js
import { useState, useContext } from "react";

import { Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import { ChatBubbleOutline, Star, StarBorder } from "@mui/icons-material";
import axios from "axios";
import { API_BASE, currentUser } from "@/App";
import { getContentArtwork } from "@/utils";
import ReactPlayer from "react-player";
import ActiveContentDrawer from "@/components/ActiveContentDrawer";
import { MusicContext } from "@/contexts/MusicContext";

export default function FeaturedContainer({ artistContent }) {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(36,36,36,1) 0%, rgba(18,18,18,0) 50%)",
        pt: 4,
        pb: 6,
        px: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 4, fontWeight: 800 }}>
        Featured
      </Typography>

      {/* Container for horizontal scroll */}
      <Box
        sx={{
          display: "flex",
          overflowX: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          "&::-webkit-scrollbar": {
            /* WebKit */ display: "none",
          },
        }}
      >
        {artistContent.map((content, index) => (
          <Box key={index} sx={{ mr: 2 }}>
            {content.type == "video" && <FeaturedVideo content={content} />}
            {content.type == "audio" && <FeaturedAudio content={content} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function FeaturedAudio({ content }) {
  const { currentSong, music, handlePlay } = useContext(MusicContext);
  const handleClick = () => {
    // get the music el that matches the content key
    const musicEl = music.find((el) => el.key === content.key);
    console.log("FeaturedAudio", musicEl);
    handlePlay(musicEl);
  };
  return (
    <Box onClick={handleClick}>
      <CardMedia
        component="img"
        sx={{
          borderRadius: "15px",
          height: "300px",
          width: "300px",
        }}
        src={getContentArtwork(content)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          mt: 2,
        }}
      >
        <Typography
          variant="body"
          color={currentSong?.key === content.key ? "secondary" : "inherit"}
        >
          {content.meta.title}
        </Typography>
      </Box>
    </Box>
  );
}

function FeaturedVideo({ content }) {
  const [favorite, setFavorite] = useState(content.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(content.favorite_count);
  const [open, setOpen] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box>
      {console.log(content)}

      {content.type == "audio" && (
        <CardMedia
          component="img"
          sx={{
            borderRadius: "15px",
            height: "300px",
            width: "300px",
          }}
          src={getContentArtwork(content)}
        />
      )}
      {content.type == "video" && (
        <Card style={{ borderRadius: "15px" }}>
          <ReactPlayer
            url={content.meta.url}
            light={content.meta.cover}
            playing
            controls
            height="300px"
            width="300px"
            // onContextMenu={preventLongPressMenu}
          />
        </Card>
      )}

      <Box
        id="post-actions"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <Box id="handle-favorite" sx={{ ml: -1, mr: 1 }}>
          <IconButton onClick={handleFavorite}>
            {favorite ? <Star color="secondary" /> : <StarBorder />}
          </IconButton>
          <Typography variant="caption">
            {favoriteCount > 0 && favoriteCount}
          </Typography>
        </Box>
        <Box id="handle-chat">
          <IconButton
            onClick={() => setOpen(true)}
            style={{ marginLeft: "5px" }}
          >
            <ChatBubbleOutline />
          </IconButton>
          <Typography variant="caption" style={{ marginRight: "5px" }}>
            {content.comment_count > 0 && content.comment_count}
          </Typography>
        </Box>
      </Box>
      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
