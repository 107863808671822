import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Stack,
} from "@mui/material";
import {
  AddShoppingCartOutlined,
  AttachFile,
  Poll,
  Event,
  LiveTv,
} from "@mui/icons-material";

import NewNote from "./NewNote";
import NewImage from "./NewImage";
import NewVideo from "./NewVideo";
import NewAudio from "./NewAudio";
import NewExperience from "./NewExperience";
import NewPoll from "./NewPoll";
import NewMerch from "./NewMerch";
import NewCarousel from "./NewCarousel";
// import NewStream from "./NewStream"; // example commented out
import { useNavigate } from "react-router-dom";
import { API_BASE, currentUser } from "@/App";

export default function Post() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [contentType, setContentType] = useState("note");
  // Add a pinned state (default false)
  const [pinned, setPinned] = useState(false);

  const componentMap = {
    audio: <NewAudio file={file} setFile={setFile} />,
    image: <NewImage file={file} />,
    carousel: <NewCarousel files={files} />,
    video: <NewVideo file={file} />,
    experience: <NewExperience />,
    poll: <NewPoll />,
    merch: <NewMerch />,
  };

  const setFileType = (file) => {
    if (file.type.includes("audio")) {
      setContentType("audio");
    } else if (file.type.includes("image") && files.length > 1) {
      setContentType("carousel");
    } else if (file.type.includes("image")) {
      setContentType("image");
    } else if (file.type.includes("video")) {
      setContentType("video");
    }
  };

  useEffect(() => {
    if (file) {
      setFileType(file);
    }
  }, [file]);

  return (
    <Box sx={{ minHeight: "80vh" }}>
      {contentType === "note" && (
        <>
          <NewNote />

          {/* New toggle for "Pin It"
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{ width: "100%", justifyContent: "flex-end", pr: 2 }}
          >
            <Typography
              variant="caption"
              sx={{ color: pinned ? "primary.main" : "darkGray" }}
            >
              Pin It
            </Typography>

            <Switch
              checked={pinned}
              onChange={(e) => setPinned(e.target.checked)}
              color="primary"
            />
          </Stack> */}

          <ButtonRow
            setFile={setFile}
            setContentType={setContentType}
            setFiles={setFiles}
          />
        </>
      )}

      {/* Render the selected component (audio, image, etc.) */}
      {componentMap[contentType]}
    </Box>
  );
}

function ButtonRow({ setFile, setContentType, setFiles }) {
  const buttonStyle = {
    border: "1px solid #9A5AEF",
    marginRight: "20px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        mt: 2,
        pl: 2.5,
        gap: 3,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <AttachFileButton setFile={setFile} setFiles={setFiles} />
        <Typography variant="caption">Add music, image(s), video</Typography>
      </Box>

      <GoLiveButtonContainer setContentType={setContentType} />

      {/* poll */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          variant="outlined"
          style={buttonStyle}
          onClick={() => setContentType("poll")}
        >
          <Poll />
        </IconButton>
        <Typography variant="caption">Create a poll</Typography>
      </Box>

      {/* merch */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          variant="outlined"
          style={buttonStyle}
          onClick={() => setContentType("merch")}
        >
          <AddShoppingCartOutlined />
        </IconButton>
        <Typography variant="caption">Offer merch</Typography>
      </Box>

      {/* experience */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          variant="outlined"
          style={buttonStyle}
          onClick={() => setContentType("experience")}
        >
          <Event />
        </IconButton>
        <Typography variant="caption">Plan a meetup</Typography>
      </Box>
    </Box>
  );
}

function GoLiveButtonContainer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleGoLive = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/go_live";
    const response = await axios.post(endpoint, { user_key: currentUser.key });
    navigate(`/live?key=${response.data.key}&uri=${response.data.uri}`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        variant="outlined"
        style={{ border: "1px solid #04FFFF", marginRight: "20px" }}
        onClick={handleGoLive}
      >
        {loading ? <CircularProgress size={24} /> : <LiveTv />}
      </IconButton>
      <Typography variant="caption">Start Live streaming</Typography>
    </Box>
  );
}

function AttachFileButton({ setFile, setFiles }) {
  const handleFileChange = (e) => {
    setFiles(e.target.files);
    setFile(e.target.files[0]);
  };

  return (
    <Box>
      <input
        accept="audio/*;image/*;video/*"
        style={{ display: "none" }}
        id="icon-button-file"
        type="file"
        multiple
        onChange={handleFileChange}
      />
      <label htmlFor="icon-button-file">
        <IconButton
          variant="outlined"
          style={{ background: "#9A5AEF", marginRight: "20px" }}
          component="span"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("icon-button-file").click();
          }}
        >
          <AttachFile />
        </IconButton>
      </label>
    </Box>
  );
}
