import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Paper,
  Icon,
  IconButton,
} from "@mui/material";
import UserDrawer from "@/components/UserDrawer";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Assume these are defined elsewhere in your app
import { currentUser, API_BASE } from "@/App";
import { Instagram } from "@mui/icons-material";
import SpotifyIcon from "@/fragments/SpotifyIcon";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#A78BFA",
    },
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
  },
});

const UserAvatar = ({ user, onClick, maxEvents }) => {
  const heat = user.event_count / maxEvents;
  return (
    <Avatar
      alt={user.name}
      src={user.meta?.profile}
      sx={{
        width: 80,
        height: 80,
        cursor: "pointer",
        border: `2px solid rgba(157, 127,243, ${heat})`,
        transition: "all 0.2s ease-in-out",
      }}
      onClick={onClick}
    />
  );
};

export default function FrontRow() {
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limit, setLimit] = useState(9);
  const [maxEvents, setMaxEvents] = useState(0);

  const fetchFrontRow = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE}/data/front-row`, {
        params: {
          artist_key: currentUser.key,
          limit: 5000,
        },
      });
      setAllUsers(response.data.front_row || []);
      setMaxEvents(response.data.front_row[0]?.event_count || 0);
    } catch (error) {
      console.error("Error fetching front row data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFrontRow();
  }, []);

  // Filter users based on limit
  const displayedUsers = allUsers.slice(0, limit);

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(3, 1fr)",
      sm: "repeat(3, 1fr)",
    },
    gap: 3,
    my: 3,
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "background.default",
          borderRadius: 3,
          px: 2,
          py: 4
        }}
      >
        <Box>
          <Typography
            variant="h5"
            color="primary"
            sx={{
              fontWeight: 600,
              mb: 3,
            }}
          >
            Audience
          </Typography>

          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 5,
              p: 0.5,
              display: "inline-flex",
              mb: 3,
            }}
          >
            <ToggleButtonGroup
              value={limit}
              exclusive
              onChange={(e, newValue) => newValue && setLimit(newValue)}
              aria-label="data window"
              size="small"
              sx={{
                "& .MuiToggleButton-root": {
                  border: "none",
                  borderRadius: 4,
                  px: 3,
                  py: 1,
                  color: "grey.400",
                  "&.Mui-selected": {
                    bgcolor: "background.default",
                    color: "white",
                    "&:hover": {
                      bgcolor: "background.default",
                    },
                  },
                },
              }}
            >
              <ToggleButton value={9}>FRONT ROW</ToggleButton>
              <ToggleButton value={5000}>
                30 DAY ACTIVE
                {allUsers.length > 0 && ` (${allUsers.length})`}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {loading && (
            <Box sx={gridContainerStyle}>
              {Array(9)
                .fill(null)
                .map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={80}
                      height={80}
                      sx={{ bgcolor: "background.paper" }}
                    />
                    <Skeleton
                      variant="text"
                      width={60}
                      sx={{ bgcolor: "background.paper" }}
                    />
                  </Box>
                ))}
            </Box>
          )}

          {!loading && allUsers.length === 0 && (
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                mt: 2,
                textAlign: "center",
              }}
            >
              No audience members yet
            </Typography>
          )}

          <Box sx={gridContainerStyle}>
            {displayedUsers.map((user, index) => (
              <Box
                key={user.key || index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <UserAvatar
                  user={user}
                  onClick={() => setSelectedUser(user)}
                  maxEvents={maxEvents}
                />
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Typography variant="body2">{user.name}</Typography>
                  {user.meta.instagram && <Instagram sx={{ fontSize: 16 }} />}
                  {user.meta.spotify && <SpotifyIcon size={16} />}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {selectedUser && (
          <UserDrawer
            userKey={selectedUser.key}
            open={!!selectedUser}
            setOpen={(open) => !open && setSelectedUser(null)}
          />
        )}
      </Paper>
    </ThemeProvider>
  );
}
