import React, { useEffect, useRef, useState } from "react";

import {
  AppBar,
  Autocomplete,
  Box,
  Card,
  CardMedia,
  IconButton,
  LinearProgress,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  ArrowUpward,
  Close,
  ContentCut,
  Edit,
  Pause,
  PlayArrow,
  Star,
  StarBorder,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";

import axios from "axios";
import { API_BASE, authHeaders, currentUser } from "../../App";
import { formatSeconds } from "../../utils";

import ContentTextField from "./ContentTextField";
import TrimAudio from "./TrimAudio";

import AppIcon from "../../assets/app-icon.svg";
import NotebookIcon from "../../fragments/NotebookIcon";

export default function NewAudioPost({ file, setFile }) {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState(0);
  const [lyrics, setLyrics] = useState("");
  const [cover, setCover] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [trimming, setTrimming] = useState(false);

  // Featured state (default false), toggled by Star/StarBorder icon.
  const [featured, setFeatured] = useState(false);

  const [expiresIn, setExpiresIn] = useState(315360000); // 10 years/"never"

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "audio");
    formData.append("file", file);
    formData.append("cover", cover);

    // Include featured in the meta
    formData.append(
      "meta",
      JSON.stringify({
        text,
        title,
        duration,
        lyrics,
        expires_in: expiresIn,
        featured, // <-- added
      })
    );

    try {
      const endpoint = API_BASE + "/v1/post";
      await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!file) return;
    if (title === "") {
      // Basic guess at title from filename
      setTitle(
        file.name.replace(".mp3", "").replace(".wav", "").replace(".m4a", "")
      );
    }
  }, [file]);

  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 200%)",
        pb: 4,
      }}
    >
      <PageAppBar
        handleSubmit={handleSubmit}
        loading={loading}
        featured={featured}
        setFeatured={setFeatured}
      />
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField
          text={text}
          setText={setText}
          placeholder="Start a conversation with your fans..."
        />
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          InputProps={{
            style: {
              borderRadius: "25px",
            },
          }}
        />

        {trimming ? (
          <TrimAudio
            file={file}
            setFile={setFile}
            duration={duration}
            setDuration={setDuration}
            setTrimming={setTrimming}
          />
        ) : (
          <MusicCard
            file={file}
            title={title}
            setTitle={setTitle}
            duration={duration}
            setDuration={setDuration}
            cover={cover}
            setCover={setCover}
            setTrimming={setTrimming}
          />
        )}

        <TextField
          label="Lyrics"
          value={lyrics}
          onChange={(e) => setLyrics(e.target.value)}
          fullWidth
          multiline
          minRows={2}
          maxRows={4}
          InputProps={{
            style: {
              borderRadius: "25px",
            },
          }}
        />

        <Autocomplete
          id="tag-collaborators"
          multiple
          fullWidth
          value={tags}
          onChange={(e, newValue) => setTags(newValue)}
          freeSolo
          options={[
            "Julia Michaels",
            "John Ryan",
            "Thomas Rhett",
            "Teddy Swims",
            "Mikky Ekko",
            "Julian Bunetta",
            "sasha alex sloan",
          ]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Featuring"
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: "25px",
                },
              }}
            />
          )}
        />

        <ExpirationSlider expiresIn={expiresIn} setExpiresIn={setExpiresIn} />
      </Box>
    </Box>
  );
}

function MusicCard({
  file,
  setFile,
  title,
  setTitle,
  duration,
  setDuration,
  cover,
  setCover,
  setTrimming,
}) {
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [audioUrl, setAudioUrl] = useState("");

  const audioPostRef = useRef(null);

  const handlePlayPause = () => {
    if (audioPostRef.current.paused) {
      audioPostRef.current.play();
      setIsPlaying(true);
    } else {
      audioPostRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioPostRef.current) {
      setDuration(audioPostRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (audioPostRef.current) {
      setCurrentTime(audioPostRef.current.currentTime);
    }
  };

  useEffect(() => {
    if (!file) return;
    // Revoke previous URL if any
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl);
    }
    setAudioUrl(URL.createObjectURL(file));
  }, [file]);

  useEffect(() => {
    if (!file) return;
    if (title === "") {
      setTitle(file.name.replace(".mp3", "").replace(".wav", ""));
    }
  }, [file]);

  return (
    <Card
      style={{
        borderRadius: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        id="cover-photo"
        sx={{ position: "relative", width: 150, height: 150 }}
      >
        <CardMedia
          component="img"
          sx={{ width: 150, height: 150 }}
          image={
            cover
              ? URL.createObjectURL(cover)
              : currentUser.meta.artwork || AppIcon
          }
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="cover-photo-input"
          type="file"
          onChange={(e) => setCover(e.target.files[0])}
        />
        <IconButton
          sx={{
            position: "absolute",
            bottom: 15,
            left: 8,
            opacity: 0.8,
          }}
          onClick={() => document.getElementById("cover-photo-input").click()}
        >
          <Edit />
        </IconButton>
      </Box>
      <Box sx={{ width: "100%", px: 3 }}>
        <audio
          ref={audioPostRef}
          src={audioUrl}
          controls
          onLoadedMetadata={handleLoadedMetadata}
          onTimeUpdate={handleTimeUpdate}
          style={{ display: "none" }}
        />
        <Box>
          <Slider
            id="current-time-slider"
            value={currentTime}
            color="secondary"
            onChange={(e, newValue) => {
              if (audioPostRef.current) {
                audioPostRef.current.currentTime = newValue;
              }
            }}
            max={duration}
          />
          <TimeRow currentTime={currentTime} duration={duration} />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <IconButton onClick={() => setTrimming(true)}>
              <ContentCut color="info" />
            </IconButton>
            <IconButton onClick={handlePlayPause} sx={{ p: 0 }}>
              {isPlaying ? (
                <Pause style={{ fontSize: "50px" }} />
              ) : (
                <PlayArrow style={{ fontSize: "50px" }} />
              )}
            </IconButton>
            <Box sx={{ width: "40px" }} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

function ExpirationSlider({ expiresIn, setExpiresIn }) {
  const [value, setValue] = useState(6);

  const expiresInOptions = [
    { label: "24hr", value: 1 },
    { label: "1wk", value: 2 },
    { label: "1m", value: 3 },
    { label: "3m", value: 4 },
    { label: "6m", value: 5 },
    { label: "Never", value: 6 },
  ];

  function valuetext() {
    const option = expiresInOptions.find((option) => option.value === value);
    return option ? option.label : "";
  }

  const optionsSecondsMap = {
    1: 86400, // 24 hours
    2: 604800, // 7 days
    3: 2592000, // 1 month
    4: 7776000, // 3 months
    5: 15552000, // 6 months
    6: 315360000, // 10 years
  };

  const handleExpiresChange = (event, newValue) => {
    setValue(newValue);
    setExpiresIn(optionsSecondsMap[newValue]);
  };

  return (
    <Box sx={{ p: 1, opacity: 0.6 }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1">Expires:</Typography>
        <Typography variant="body1">{valuetext()}</Typography>
      </Box>
      <Slider
        aria-label="Expires in"
        value={value}
        onChange={handleExpiresChange}
        step={null}
        min={0.8}
        max={6.2}
        valueLabelDisplay="off"
        marks={expiresInOptions}
        sx={{ color: "white" }}
      />
    </Box>
  );
}

export function TimeRow({ currentTime, duration }) {
  return (
    <Box
      id="time-row"
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "-10px",
      }}
    >
      <Typography variant="caption" color="textSecondary">
        {formatSeconds(currentTime)}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        - {formatSeconds(duration - currentTime)}
      </Typography>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading, featured, setFeatured }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => window.location.reload()} sx={{ pl: 0 }}>
          <Close style={{ color: "darkgray" }} />
        </IconButton>

        <Typography
          variant="h6"
          style={{ color: "white", fontSize: "1.1rem" }}
          sx={{ ml: 6 }}
        >
          New Music
        </Typography>

        {/* Right Side: Pin + Submit */}
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="caption"
            color={featured ? "secondary" : "darkgray"}
          >
            PIN
          </Typography>
          <IconButton
            onClick={() => setFeatured(!featured)}
            sx={{ mr: 2, transform: "rotate(45deg)" }}
          >
            {featured ? (
              <PushPin color="secondary" />
            ) : (
              <PushPinOutlined sx={{ color: "darkgray" }} />
            )}
          </IconButton>
          <IconButton
            size="small"
            style={{
              background: "#9A5AEF",
            }}
            onClick={handleSubmit}
            disabled={loading}
          >
            <ArrowUpward />
          </IconButton>
        </Box>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
