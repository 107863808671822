import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import NewUserContainer from "./NewUserContainer";
import AppLogo from "@/assets/app-logo.svg";
import FooterLinks from "@/fragments/FooterLinks";
import { API_BASE, artistKey } from "@/App";
import { setCurrentUser, setArtistKey, setToken } from "@/utils";
import { countryCodes } from "@/Constants";
import { Lock, Send } from "@mui/icons-material";

export default function LoginContainer() {
  console.log("artistKey", artistKey);

  const { key } = useParams();

  useEffect(() => {
    const validateArtistKey = async () => {
      if (key) {
        try {
          const endpoint = API_BASE + "/valid-artist-key";
          const response = await axios.get(endpoint, {
            params: { key },
          });

          if (response.data.valid) {
            console.log("Artist Key:", key);
            setArtistKey(key);
            window.location.href = "/";
          } else {
            console.error("Invalid artist key");
            localStorage.removeItem("artistKey");
          }
        } catch (error) {
          console.error("Error validating artist key:", error);
          setArtistKey(null);
        }
      }
    };

    validateArtistKey();
  }, [key]);

  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+1"); // default to US [+1
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  const [sent, setSent] = useState(false);
  const [verified, setVerified] = useState(false);

  const formattedPhone = () => {
    return countryCode + phone;
  };

  const sendVerification = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/send_verification";
      const response = await axios.get(endpoint, {
        params: { phone: formattedPhone() },
      });
      setSent(true);
      setLoading(false);
    } catch (error) {
      alert("Something went wrong. Please contact support@notebook.fm");
      window.location.reload();
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/verify";
      const response = await axios.get(endpoint, {
        params: { phone: formattedPhone(), code: code },
      });

      // if response data user
      if (response.data.user) {
        setCurrentUser(response.data.user);
        setToken(response.data.token);
        await new Promise((resolve) => setTimeout(resolve, 500)); // do I still need this?
        if (artistKey) {
          window.location.href = `/${artistKey}`;
        } else if (response.data.user.role == "artist") {
          setArtistKey(response.data.user.key);
          window.location.href = `/${response.data.user.key}`;
        } else if (response.data.subscriptions.length > 0) {
          const firstArtistKey = response.data.subscriptions[0].artist_key;
          setArtistKey(firstArtistKey);
          window.location.href = `/${firstArtistKey}`;
        } else {
          window.location.href = "/subscriptions";
        }
      } else if (response.data.verified) {
        setLoading(false);
        setVerified(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert(
        "Something went wrong. Please try again or contact support@notebook.fm"
      );
      window.location.href = "/logout";
      // window.location.reload();
    }
  };

  const validLength = () => {
    if (countryCode === "+1") {
      return phone.length >= 10;
    } else {
      return phone.length >= 8;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "80vh",
          pt: 10,
        }}
      >
        <img
          src={AppLogo}
          style={{ maxWidth: "350px", marginBottom: "0px", padding: "0" }}
        />

        <Typography variant="body1" sx={{ mb: 3 }}>
          Connecting artists with their audience.{" "}
        </Typography>

        {loading && <CircularProgress sx={{ mt: 5 }} />}

        {!sent && !loading && (
          <Box style={{ margin: "20px 0" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Autocomplete
                id="country-code"
                value={countryCode}
                onChange={(e, newValue) => setCountryCode(newValue)}
                options={countryCodes}
                disableClearable
                sx={{ mr: 1 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                type="telephone"
                value={phone}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/[^0-9]/g, ""))
                }
                fullWidth
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && validLength()) {
                    sendVerification();
                  }
                }}
              />
            </Box>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={sendVerification}
              disabled={!validLength()}
              startIcon={<Send />}
              style={{ margin: "20px 0" }}
            >
              Request code
            </Button>
          </Box>
        )}

        {sent && !verified && !loading && (
          <Box style={{ margin: "20px 0", width: "300px" }}>
            <TextField
              label="Code"
              variant="outlined"
              value={code}
              helperText="Enter the code you received via SMS"
              onChange={(e) => setCode(e.target.value.replace(/[^0-9]/g, ""))}
              fullWidth
              autoComplete="off"
              style={{ margin: "20px 0" }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleVerify();
                }
              }}
            />
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleVerify}
              disabled={code.length != 6}
              startIcon={<Lock />}
            >
              Confirm
            </Button>
          </Box>
        )}

        {verified && !loading && (
          <NewUserContainer
            countryCode={countryCode}
            phone={phone}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Box>
      {!sent && <FooterLinks />}
    </Box>
  );
}
