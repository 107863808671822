import axios from "axios";
import React, { useState } from "react";

import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Popper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  Cancel,
  Check,
  Delete,
  Edit,
  IosShare,
  MoreHoriz,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";

import { API_BASE, currentUser } from "../App";

export default function ActionDrawer({ content }) {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [caption, setCaption] = useState(content.meta.text);
  const [lyrics, setLyrics] = useState(content.meta.lyrics);
  const [when, setWhen] = useState(content.meta.when);
  const [location, setLocation] = useState(content.meta.location);
  const [tags, setTags] = useState(content.meta.tags || []);
  const [loading, setLoading] = useState(false);
  const [featured, setFeatured] = useState(content.meta.featured);

  const copyLink = () => {
    const link = window.location.origin + "/" + content.owner.key;
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard.");
    setOpen(false);
  };

  const handleRemoveContent = async () => {
    const result = confirm(
      "Are you sure you want to remove this content from your Notebook?"
    );
    if (result) {
      const endpoint = API_BASE + "/content/remove";
      const response = await axios.post(endpoint, {
        user_key: currentUser.key,
        content_key: content.key,
      });
      setOpen(false);
      window.location.reload();
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/content/update";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      meta: {
        text: caption,
        lyrics: lyrics,
        tags: tags,
        when: when,
        location: location,
      },
    });
    window.location.reload();
  };

  const handleCloseEvent = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/content/completed";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
    });
    window.location.reload();
  };

  const handleCancel = () => {
    setOpen(false);
    setEditing(false);
  };

  const handleToggleFeatured = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/content/featured";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
    });
    window.location.reload();
  };

  return (
    <Box>
      <IconButton
        onClick={() => setOpen(true)}
        style={{ marginLeft: "5px" }}
        // sx={{ py: 0 }}
      >
        <MoreHoriz style={{ color: "white" }} />
      </IconButton>

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { borderRadius: "30px 30px 0 0" } }}
        elevation={4}
      >
        <AppBar
          position="sticky"
          elevation={0}
          color="transparent"
          sx={{ my: 2 }}
        >
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton onClick={handleCancel} color="info">
              <Cancel />
            </IconButton>
            {content.type == "audio" && (
              <Typography variant="h6" color="info">
                {content.meta.title}
              </Typography>
            )}
            {editing ? (
              <Button
                variant="outlined"
                onClick={handleSave}
                style={{ borderRadius: "30px" }}
                disabled={loading}
              >
                Save
              </Button>
            ) : (
              <Box sx={{ width: "60px" }} />
            )}
          </Toolbar>
        </AppBar>
        <Box style={{ minHeight: "50vh" }} sx={{ px: 2, pb: 10 }}>
          {editing && (
            <Box>
              <TextField
                id="caption"
                label="Caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                fullWidth
                multiline
                size="small"
                sx={{ my: 2 }}
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              {content.type == "experience" && (
                <>
                  <TextField
                    label="When"
                    value={when}
                    onChange={(e) => setWhen(e.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    size="small"
                    sx={{ my: 2 }}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                      },
                    }}
                  />
                  <TextField
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    size="small"
                    sx={{ my: 2 }}
                    InputProps={{
                      style: {
                        borderRadius: "30px",
                      },
                    }}
                  />
                </>
              )}
              {content.type == "audio" && (
                <TextField
                  id="lyrics"
                  label="Lyrics"
                  value={lyrics}
                  onChange={(e) => setLyrics(e.target.value)}
                  fullWidth
                  multiline
                  rows={6}
                  size="small"
                  sx={{ my: 2 }}
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                    },
                  }}
                />
              )}
            </Box>
          )}

          {!editing && (
            <>
              <List>
                {content.owner.key == currentUser.key && (
                  <>
                    <ListItem
                      button
                      key="edit-content"
                      onClick={() => setEditing(!editing)}
                      sx={{ mb: 2 }}
                    >
                      <Edit sx={{ mr: 2 }} color="info" />
                      <Typography>Edit Content</Typography>
                    </ListItem>
                    <ListItem
                      button
                      key="remove-content"
                      onClick={handleRemoveContent}
                      sx={{ mb: 2 }}
                    >
                      <Delete sx={{ mr: 2 }} color="info" />
                      <Typography>Remove Content</Typography>
                    </ListItem>
                    <ListItem
                      button
                      key="feature-content"
                      onClick={handleToggleFeatured}
                      sx={{ mb: 2, display: "flex", alignItems: "center" }}
                    >
                      {featured ? (
                        <PushPin color="secondary" sx={{ mr: 2 }} />
                      ) : (
                        <PushPinOutlined sx={{ color: "darkgray", mr: 2 }} />
                      )}

                      <Typography>
                        {featured ? "Remove from Featured" : "Pin to Featured"}
                      </Typography>
                    </ListItem>
                  </>
                )}
                <ListItem button key="3" onClick={copyLink} sx={{ mb: 2 }}>
                  <IosShare sx={{ mr: 2 }} color="info" />
                  <Typography>Share</Typography>
                </ListItem>
              </List>
              {content.type == "experience" &&
                !content.meta.closed &&
                currentUser.role == "artist" &&
                currentUser.key == content.owner.key && (
                  <Box sx={{ px: 2 }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      sx={{ my: 4 }}
                      style={{ borderRadius: "30px" }}
                      startIcon={<Check />}
                      onClick={handleCloseEvent}
                    >
                      Mark Complete
                    </Button>
                  </Box>
                )}
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

function CustomPopper(props) {
  return <Popper {...props} placement="auto" />;
}
